* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html{
	scroll-behavior: smooth;
}

::-webkit-scrollbar{ 
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #CE6D34;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d1865b;
}