.clients {
    width: 100%;
    height: 100vh;
    background-image: url("../img/clients/bg.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position:  left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clients-content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    justify-content: center;
    align-items: center;
}

.clients-content-left {
    width: 100%;
}

.clients-content-right {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-gap: 30px 0 ;
} 

.clients-content-client {
    width: 100%;
}

.clients-content-client img {
    width: 500px;
    transition: 1s all ease;
    
}

@media screen and (max-width: 1450px) {
    .clients-content {
        grid-template-columns: repeat(1, 1fr);
    }

    .clients-content-right {
        margin-top: 50px;
        grid-template-columns: repeat(2, 1fr);
    }
    
    .clients-content-client img {
        width: 400px;
    } 
    
}

@media screen and (max-width: 1250px) {
    .clients-content-right {
        margin-top: 50px;
        grid-template-columns: repeat(2, 1fr);
    } 
}

@media screen and (max-width: 960px) {
    .clients-content {
        grid-template-columns: repeat(1, 1fr);
    }

    .clients-content-right {
        display: none;
    }
}
