.bg-transparent {
    background-color: transparent;
}

.bg-navbar {
    background-image: url("../img/clients/bg.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position:  left;
    border-bottom: 3px solid #ce6d34;
}

.nb-container {
    position: sticky;
    top: 0.1px;
}

.navbar-container {
    position: absolute;
    width: 100%;
    padding: 20px 11%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-container a {
    color: white;
    text-decoration: none;
    padding-left: 23px;
}

.navbar-sections {
    display: flex;
}

.navbar-sections-navegation {
    display: flex;
    align-items: center;
    margin-right: 50px;
}

.woombatLg {
    width: 14%;
}

.translation-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.translation-btn {
    color: #fff;
    cursor: pointer;
    margin-bottom: 3px;
}

@media(max-width: 890px) {
    .navbar-container a {
        color: white;
        text-decoration: none;
        padding-left: 10px;
    }
}

@media(max-width: 809px) {
    .navbar-container a {
        padding-left: 5px;
    }

    .navbar-sections-navegation {
        margin-right: 10px;
    }
}

@media(max-width: 724px) {
    .navbar-container {
        display: none;
    }
}
