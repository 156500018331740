.presentation-container {
    height: 80vh;
    display: flex;
}

.part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 157%;
    width: 50%;
}

.part h1{
    color: rgb(255, 255, 255);
    margin-bottom: 5%;
}

.part h3 {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.685);
}

.part2 {
    width: 50%;
}

@media (max-width: 962px) {
    .part2 {
        display: none;
    }

    .part{
        text-align: center;
        width: 80%;
        margin: auto;
    }
}

@media(max-width: 594px) {
    .presentation-container {
        margin-top: 0px;
    }

    .part {
        font-size: 100%;
    }
}