.content {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-section {
    width: 50%;
}

.rigth-section {
    border-radius: 10px;
    background-color: #ecf0f1;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
}

.contactUs-logo { 
    background-color: #26293D;
    padding: 5% 10%;
    border-radius: 5px 5px 0px 0px;
}

.tx-contactUs {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tx-contactUs h2 {
    font-size: 40px;
    margin-right: 20px;
    color: #26293D;
}


.formulario {
    height: 0px;
    overflow: hidden;
}

.formulario-on {
    height: 100%;
    margin-top: 22px;
}



/*FORMULARIOS*/
.inputs {
    width: 90%;
    border: 2px solid rgb(245, 245, 245);
    margin-bottom: 15px;
    padding: 10px 5px 10px;
    display: flex;
    border-radius: 5px;
}

.area-text {
    width: 90%;
    height: 95px;
    border: 2px solid rgb(245, 245, 245);
}

.auth-content {
    width: 90%;
    height: 200px;
    overflow: scroll;
    border: 2px solid rgb(245, 245, 245);
    border-radius: 5px;
    padding: 15px;
}

.auth-content h3{
    text-align: center;
    margin-bottom: 30px;
    color: #26293D;
}

.auth-content p {
    color: #707070;
}

.auth {
    display: flex;
    align-items: center;
    width: 90%;
}

.auth input {
    margin-right: 10px;
}

.auth p {
    font-weight: 700;
    color: #707070;
}

.btn-active {
    border: 0;
    padding: 10px 25px;
    color: white;
    background-color: #26293D;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.btn-active:hover {
    color: #CE6D34;
}

.btn-none {
    border: 0;
    padding: 10px 25px;
    color: white;
    background-color: #5b5b5c;
    border-radius: 5px;
    cursor: no-drop;
}

@media(max-width: 954px) {
    .auth-content {
        width: 100%;
    }

    .tx-contactUs h2 {
        display: none;
    }
    
    .inputs {
        width: 100%;
    }

    .area-text {
        width: 100%;
    }

    .content {
        flex-direction: column;
    }

    .left-section {
        width: 100%;
    }

    .rigth-section {
        width: 100%;
        margin-top: 30px;
    }
}