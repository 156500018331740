.allies {
    width: 100%;
    height: 100vh;
    background-image: url("../img/allies/bg.jpeg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position:  center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.allies-content {
    margin-top: 25px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 25px 0px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.allies-content-allie {
    width: 100%;
}

.allies-content-allie img {
    width: 400px;
}

@media screen and (max-width: 1560px) {
    .allies-content {
        grid-template-columns: repeat(3, 1fr);
    }

    .allies-content-allie img {
        width: 300px;
    }
}

@media screen and (max-width: 1100px) {
    .allies-content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 960px) {

    .allies {
        background-position: right;

    }

    .allies-content {
        display: none;
    }
}
