.title {
    font-size: 40px;
    font-weight: bold;
    color: #26293D;
}

.title-white {
    font-size: 40px;
    font-weight: bold;
    color: white;
}

.subtitle {
    font-size: 25px;
    color: #CE6D34;
}

.text-white {
    margin-top: 6px;
    font-size: 17px;
    color: #CE6D34;
}
.text {
    margin-top: 6px;
    font-size: 17px;
    color: #707070;
}

.line-left {
    border-left: 7px solid #CE6D34;
}

.text-center {
    text-align: center;
}

@media screen and (max-width: 1500px) {
    .title {
        font-size: 35px;
    }

    .subtitle {
        font-size: 20px;
    }

    .text {
        font-size: 16px;
    }
}