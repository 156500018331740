.burger-menu {
    display: none;
} 
.nv-burger-container {
    position: sticky;
    top: 0.1px;
}

 @media(max-width: 724px) {
    .burger-menu {
        display: block;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding-right: 10px;
        height: 2.4rem;
        position: absolute;
        width: 100%;
        background-color: #26293D;
    }

    .icon {
        font-size: 1.5rem;
        color: aliceblue;
    }

    .icon:hover {
        color: rgb(179, 179, 179);
    }
}

.on {
    display: block;
}

.off {
    display: none;
}

.burger-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    position: absolute;
    right: 1px;
    width: 100%;
    text-align: center;
    margin-top: 2px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.burger-content a {
    text-decoration: none;
    padding: 19px 57px;
    color: #CE6D34
}

.ct-links {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.burger-content a:active {
    color: #e49669;
}

.burger-content a:hover {
    background-color: rgb(187, 187, 187);
}

.burger-content select {
    border: 0px;
    padding: 10px 12px;
}