.bg-Image {
    background-image: url("../img/big-data.jpg");
    /* background-image: url('https://instaclone-steven.s3.us-west-1.amazonaws.com/AdobeStock_307461398.jpeg'); */
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
}
.bg {
    width: 100%;
    height: 100%;
    background-color:rgba(21, 21, 47, 0.74);
}

@media(max-width: 962px) {
    .bg-Image {
        width: 100vw;
    }
}
