.services {
    width: 100%;
    margin-top: 1.6rem;
}

.services-title {
    text-align: center;
    font-size: 2.3rem;
    font-weight: normal;
    margin-bottom: 50px;
}

.services-container {
    display: flex;
    height: 100vh;
}

.services-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container {
    width: 80%;
    margin: auto;
}

.rigth {
    text-align: center;
}

.subtitle {
    color: #CE6D34;
}

.text-dark {
    color: #707070;
}

.btn-services {
    border: 0;
    border-radius: 5px;
    padding: 7px 20px;
    background-color: #26293D;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

.btn-services:hover {
    color: #CE6D34;
}


.card {
    width: 92%;
    color: #26293D;
    border-radius: 5px;
}



/*cartas*/
.card-services-deploy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 9px 10px;
    cursor: pointer;
}

.texto {
    padding: 15px 15px;
    background-color: #ebebebd5;
    border-radius: 5px;
}

.card-services-deploy:hover {
    background-color: #ebebebd5;
}

.desbord-none {
    display: none;
}

.desbord-block {
    display: block;
    margin-bottom: 20px;
    font-size: 15px;
}


@media screen and (max-width: 711px) {
    .services-info {
        width: 100%;
    }

    .services-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .left {
        margin-bottom: 50px;
    }

    .desbord-block {
        font-size: 10px;
        margin-bottom: 5px;
    }

    .card-services-deploy {
        padding: 5px 10px;
    }
}