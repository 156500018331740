.error {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../img/clients/bg.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position:  left;
    color: white;
}

.error-container {
    width: 50%;
    margin: auto;
    text-align: center;
}

.error-text {
    font-weight: bold;
}

@media screen and (max-width: 690px) {
    .error-container {
        width: 90%;
        margin: auto;
        text-align: center;
    }
}